<template>
  <!-- 批量收货 -->
  <div class="main-view">
    <div
      style="
        background: #fff;
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
      "
    >
      <div
        class="main"
        style="flex: 1; display: flex; flex-direction: column; overflow: hidden"
      >
        <div class="mainBox" style="padding: 20px; flex: 1; overflow-y: scroll">
          <div class="itemTime">基础信息</div>
          <el-descriptions title="">
            <el-descriptions-item label="供应商/采购员">{{
              supplier_name
            }}</el-descriptions-item>
            <el-descriptions-item label="交货日期">{{
              delivery_date
            }}</el-descriptions-item>
            <el-descriptions-item label="采购笔数">{{
              info.order_count
            }}</el-descriptions-item>
            <el-descriptions-item label="下单数量">{{
              info.num
            }}</el-descriptions-item>
            <el-descriptions-item label="发货数量">{{
              info.send_num
            }}</el-descriptions-item>
            <el-descriptions-item label="发货金额"
              >¥{{ info.send_price }}</el-descriptions-item
            >
            <el-descriptions-item label="已收数量">{{
              info.delivery_num
            }}</el-descriptions-item>
            <el-descriptions-item label="已收金额"
              >¥{{ info.delivery_money }}</el-descriptions-item
            >
            <el-descriptions-item label="待收数量">{{
              info.await_delivery_num
            }}</el-descriptions-item>
          </el-descriptions>

          <div class="itemTime">
            收货
            <span style="color: #999; font-size: 14px"
              >(共{{ table.total }}项)</span
            >
          </div>
          <el-input
            style="width: 240px; margin-bottom: 10px"
            v-model.trim="searchForm.data.goods_name"
            placeholder="请输入商品名称"
            size="small"
            clearable
            @input="
              searchForm.data.goods_name = searchForm.data.goods_name.replace(
                /^-?\d+\.?\d*$/,
                ''
              )
            "
            @keydown.enter.native="getTable"
            @clear="getTable"
          >
          </el-input>
          <!-- 有支持待分拣的采购单再显示食堂查询条件 -->
          <el-select
            v-if="is_address_sel == true"
            v-model="searchForm.data.address_id"
            filterable
            :placeholder="`请输入${userId == 5 ? '用户' : '食堂'}名称`"
            style="width: 240px; margin-bottom: 10px; margin: 0 10px"
            clearable
            size="small"
            @focus="getStore"
            @change="getTable"
          >
            <el-option
              v-for="item in storeArr"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
              <div>
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px"
                  >({{ item.merchant_name }})</span
                >
              </div>
            </el-option>
          </el-select>
          <el-input
            style="width: 240px; margin-bottom: 10px; margin: 0 10px"
            v-model.trim="searchForm.data.order_no"
            placeholder="请输入采购单号"
            size="small"
            clearable
            @clear="getTable"
            @keydown.enter.native="getTable"
          >
          </el-input>
          <el-button
            type="primary"
            size="small"
            style="margin-left: 10px"
            @click="getTable"
            >查询</el-button
          >
          <el-button
            plain
            type="primary"
            size="small"
            style="float: right"
            @click="handleBill"
            >上传单据</el-button
          >

          <div class="table-warp">
            <div v-for="(tableItem, tableIndex) in tableData" :key="tableIndex">
              <el-table
                :data="tableItem.details"
                :ref="`table${tableIndex}`"
                border
                size="mini"
                style="width: 100%"
                :header-cell-style="headerFirst"
                @selection-change="
                  handleSelectionChange($event, tableItem, tableIndex)
                "
              >
                <el-table-column width="66" fixed>
                  <!-- 采购状态待发货或待收货时加编辑操作 -->
                  <template slot="header" slot-scope="{}"></template>
                  <template
                    slot-scope="{ row, $index }"
                    v-if="tableItem.status == 0 || tableItem.status == 1"
                  >
                    <div style="display: flex; align-items: center">
                      <el-popconfirm
                        title="确认删除此商品？"
                        @confirm="onMinus(row, $index, tableItem, tableIndex)"
                      >
                        <el-button
                          slot="reference"
                          plain
                          style="width: 20px; height: 16px; padding: 0"
                          >-</el-button
                        >
                      </el-popconfirm>
                      <el-button
                        plain
                        style="
                          margin-top: 1px;
                          margin-left: 3px;
                          display: block;
                          width: 20px;
                          height: 16px;
                          padding: 0;
                        "
                        @click="onAdd(row, $index, tableIndex)"
                        >+</el-button
                      >
                    </div>
                  </template>
                </el-table-column>
                <el-table-column type="selection" width="45" fixed>
                </el-table-column>
                <el-table-column prop="date" label="" width="180" :span="2">
                  <template slot="header">
                    <span>采购单号：{{ tableItem.order_no }}</span>
                    <span style="margin-left: 58px">{{
                      tableItem.create_time
                    }}</span>
                    <span
                      style="margin-left: 58px"
                      :class="!!tableItem.is_bills ? 'greenColor' : 'redColor'"
                      >{{
                        !!tableItem.is_bills
                          ? "（已上传单据）"
                          : "（未上传单据）"
                      }}</span
                    >
                  </template>
                  <!-- 不支持分拣的供应商数据，不需要显示食堂名称字段 -->
                  <el-table-column
                    v-if="tableItem.is_sorter == 1"
                    prop="address_name"
                    label="*食堂名称"
                    width="120"
                  >
                    <template slot-scope="{ row, $index }">
                      <div v-if="!!row.address_name || row.address_id == '0'">
                        {{ row.address_name }}
                      </div>
                      <div v-else>
                        <el-select
                          v-model="row.address_id"
                          filterable
                          @focus="getStore"
                          size="mini"
                          @change="
                            changeAddress(row, $index, tableIndex, tableItem)
                          "
                        >
                          <el-option
                            v-for="item in storeArr"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          >
                            <div>
                              <span style="float: left">{{ item.name }}</span>
                              <span
                                style="
                                  float: right;
                                  color: #8492a6;
                                  font-size: 13px;
                                "
                                >({{ item.merchant_name }})</span
                              >
                            </div>
                          </el-option>
                        </el-select>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="good_img" label="商品名称" width="180">
                    <template slot-scope="{ row, $index }">
                      <div
                        style="display: flex; align-items: center"
                        v-if="!!row.id"
                      >
                        <el-image
                          v-if="!!row.image"
                          style="width: 40px; height: 40px"
                          :src="row.image"
                          fit="cover"
                          :preview-src-list="[row.image]"
                        ></el-image>
                        <el-image
                          v-else
                          style="width: 40px; height: 30px"
                          :src="require('@/assets/img/logo-icon.jpeg')"
                          fit="cover"
                          :preview-src-list="[
                            require('@/assets/img/logo-icon.jpeg'),
                          ]"
                        ></el-image>
                        <span style="margin-left: 10px">{{
                          row.good_name
                        }}</span>
                      </div>
                      <template v-else>
                        <el-select
                          v-model="row.good_name"
                          filterable
                          remote
                          placeholder="请输入关键词"
                          :remote-method="
                            (query) => remoteMethod(query, tableItem)
                          "
                          :loading="selLoading"
                          size="small"
                          @change="changeGoods($event, $index, tableIndex, row)"
                          value-key="id"
                        >
                          <el-option
                            v-for="item in goodArr"
                            :label="item.good_name"
                            :value="item"
                            :key="item.id"
                          >
                            <div>
                              <span style="float: left">{{
                                item.good_name
                              }}</span>
                              <span
                                style="
                                  float: right;
                                  color: #8492a6;
                                  font-size: 13px;
                                "
                                >{{ item.name }}</span
                              >
                            </div>
                            <div
                              style="color: #999; margin-top: -5px"
                              v-if="!!item.norm_name"
                            >
                              标名：{{ item.norm_name }}
                            </div>
                          </el-option>
                        </el-select>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="order_cate_name"
                    label="下单分类"
                    key="3"
                    width="80px"
                  >
                    <template slot-scope="{ row }">
                      <span>{{ row.order_cate_name }}</span>
                      <span v-if="!!row.order_tow_name">
                        / {{ row.order_tow_name }}</span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="unit_name"
                    label="单位"
                    width="60"
                    key="4"
                  >
                  </el-table-column>
                  <el-table-column prop="name" label="规格" key="5">
                  </el-table-column>
                  <el-table-column
                    prop="last_purchase_price"
                    label="采购价"
                    width="100px"
                    key="6"
                  >
                    <template slot-scope="{ row }">
                      {{ row.last_purchase_price }}
                      <div
                        v-if="
                          row.extent_curr != 0 && row.old_purchase_price != 0
                        "
                        style="display: inline"
                      >
                        <i
                          :class="
                            row.extent_curr > 0
                              ? 'el-icon-top redColor'
                              : 'el-icon-bottom greenColor'
                          "
                        ></i>
                        <span
                          :class="
                            row.extent_curr > 0
                              ? 'extent redColor'
                              : 'extent greenColor'
                          "
                          >{{ Math.abs(row.extent_curr) }}</span
                        >
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="status" label="采购状态" key="8">
                    <template slot-scope="{ row }">{{
                      toStr(
                        {
                          1: "待收货",
                          2: "取消",
                          3: "删除",
                          4: "部分收货",
                          5: "全部收货",
                        },
                        row.status
                      )
                    }}</template>
                  </el-table-column>
                  <el-table-column
                    prop="num"
                    label="下单数量"
                    width="100px"
                    key="9"
                  >
                    <template slot-scope="{ row, $index }">
                      <!-- 采购状态待发货或待收货时可编辑下单数量 -->
                      <el-input
                        v-if="tableItem.status == 0 || tableItem.status == 1"
                        v-model="row.num"
                        placeholder="请输入"
                        size="small"
                        @blur="numREG_EXP(row, $index, tableItem, tableIndex)"
                      ></el-input>
                      <span v-else>{{ row.num }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="delivery_num"
                    label="已收货数量"
                    width="95px"
                    key="10"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="delivery_num_curr"
                    label="本次收货数量"
                    width="110px"
                    key="11"
                  >
                    <template slot-scope="{ row, $index }">
                      <span v-if="row.status == 5">0</span>
                      <el-input
                        v-else
                        v-model="row.delivery_num_curr"
                        placeholder="请输入"
                        size="small"
                        @input="countPrice(row, $index, tableIndex)"
                      ></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="cov_unit"
                    label="采购单位"
                    width="110"
                    key="12"
                  >
                    <template slot-scope="{ row, $index }">
                      <!-- 收货完成的采购单位不可以修改 -->
                      <span v-if="row.status == 5" class="greenColor">{{
                        row.cov_unit
                      }}</span>
                      <el-button
                        v-else
                        icon="el-icon-edit"
                        type="text"
                        @click="openPopover(row, $index, tableIndex)"
                        >{{ row.cov_unit }}</el-button
                      >
                      <span v-if="row.cov_times != 0"
                        >({{ row.cov_times }}{{ row.unit_name }})</span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="delivery_money_curr"
                    label="本次收货单价"
                    width="110px"
                    key="13"
                  >
                    <template slot-scope="{ row, $index }">
                      <span v-if="row.status == 5">0</span>
                      <el-input
                        v-else
                        v-model="row.delivery_money_curr"
                        placeholder="请输入"
                        size="small"
                        @input="countPrice(row, $index, tableIndex)"
                      ></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="price"
                    label="本次收货金额"
                    width="120px"
                    key="14"
                  >
                    <template slot-scope="{ row, $index }">
                      <span v-if="row.status == 5">0</span>
                      <el-input
                        v-else
                        v-model="row.total_money_curr"
                        placeholder="请输入"
                        size="small"
                        @input="totalPrice(row, $index)"
                      ></el-input>
                    </template>
                  </el-table-column>
                  <!-- 采购状态待发货或待收货时可编辑备注 -->
                  <template
                    v-if="tableItem.status == 0 || tableItem.status == 1"
                  >
                    <el-table-column
                      prop="remarks"
                      label="备注"
                      min-width="180px"
                      key="15"
                    >
                      <template slot-scope="{ row }">
                        <!-- 采购状态待发货或待收货时可编辑备注 -->
                        <template v-if="info.status == 0 || info.status == 1">
                          <div v-for="(v, i) in row.remarks" :key="i">
                            <el-input
                              v-model="v.context"
                              placeholder="请输入（限50字）"
                              size="small"
                              type="textarea"
                              maxlength="50"
                              @blur="actionFun(row)"
                            ></el-input>
                          </div>
                        </template>
                        <template v-else>
                          <div v-for="(j, i2) in row.remarks" :key="i2">
                            {{ j.context }}
                          </div>
                        </template>
                      </template>
                    </el-table-column>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="action"
                  label="操作"
                  width="150px"
                  align="center"
                  fixed="right"
                  key="16"
                >
                  <template slot-scope="{ row }">
                    <!-- 待收货或部分收货，才有收货操作-->
                    <template v-if="row.status == 1 || row.status == 4">
                      <el-button
                        type="text"
                        slot="reference"
                        @click="handeReceivePart(row, tableItem)"
                        >部分收货</el-button
                      >
                      <el-button
                        type="text"
                        slot="reference"
                        @click="handeReceiveAll(row, tableItem)"
                        >全部收货</el-button
                      >
                    </template>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div
              style="
                text-align: right;
                font-size: 14px;
                margin-top: 10px;
                color: #777;
              "
            >
              <span
                >本次收货数量：<span
                  style="font-weight: bold; color: #f56c6c; font-size: 16px"
                  >{{ totalNumAll }}</span
                ></span
              >
              <span style="margin-left: 10px"
                >本次收货金额：<span
                  style="font-weight: bold; color: #f56c6c; font-size: 16px"
                  >￥{{ totalMoneyAll }}</span
                ></span
              >
            </div>
          </div>
        </div>
        <!-- status //状态 1:待收货 2:已关闭（已取消） 3:部分收货 4:全部收货（完成） -->
        <div
          class="footerWrap"
          v-if="info.status != 4"
          style="position: relative"
        >
          <div style="position: absolute">
            <el-button-group>
              <el-button
                type="primary"
                plain
                icon="el-icon-check"
                @click="selectAll"
                size="mini"
                >全选</el-button
              >
              <el-button
                type="primary"
                plain
                icon="el-icon-close"
                @click="selectClear"
                size="mini"
                >取消全选</el-button
              >
            </el-button-group>
          </div>
          <el-button size="medium" @click="handleBatch">部分收货</el-button>
          <el-button type="primary" size="medium" @click="handleBatchAll"
            >收货完成</el-button
          >
          <!-- <el-button type="warning" size="medium" v-if="info.status == 0 || info.status == 1" @click="handleEditInfo">保存编辑信息</el-button> -->
        </div>
      </div>

      <!-- 修改采购单位 -->
      <el-dialog
        title=""
        :visible.sync="dialogVisibleUnit"
        width="400px"
        :before-close="handleClose"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        append-to-body
        center
      >
        <el-form
          class="staff-from"
          v-loading="loading"
          element-loading-text="加载中…"
          ref="elFormDomUnit"
          label-width="60px"
          label-position="right"
          :model="formUnit.data"
          :rules="formUnit.rules"
          :size="subUnitSize"
        >
          <el-form-item label="单位">
            <el-select
              v-model="formUnit.data.cov_unit"
              size="mini"
              filterable
              @focus="getUnit"
            >
              <el-option
                v-for="item in unitArr"
                :label="item.name"
                :value="item.name"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="="
            prop="cov_times"
            v-if="formUnit.data.cov_unit != currGoodsRow.unit_name"
          >
            <el-input
              v-model="formUnit.data.cov_times"
              placeholder="请输入"
              size="small"
              @input="
                formUnit.data.cov_times = formUnit.data.cov_times.match(
                  /\d+(\.\d{0,2})?/
                )
                  ? formUnit.data.cov_times.match(/\d+(\.\d{0,2})?/)[0]
                  : ''
              "
            >
              <span slot="suffix">{{ currGoodsRow.unit_name }}</span>
            </el-input>
          </el-form-item>
        </el-form>
        <!-- 采购单位和商品单位不一致才可以点击修改按钮 -->
        <template
          #footer
          v-if="formUnit.data.cov_unit != currGoodsRow.unit_name"
        >
          <el-row type="flex" justify="center">
            <el-button type="primary" @click="handleUnit">确定</el-button>
          </el-row>
        </template>
      </el-dialog>

      <!-- 上传单据 -->
      <el-dialog
        title=""
        :visible.sync="dialogVisibleBill"
        width="400px"
        :before-close="handleClose"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        append-to-body
        center
      >
        <el-form
          class="staff-from"
          v-loading="loading"
          element-loading-text="加载中…"
          ref="elFormDomBill"
          label-width="110px"
          label-position="top"
          :model="formBill.data"
          :rules="formBill.rules"
          :size="subUnitSize"
          max-height="600px"
        >
          <el-form-item label="上传单据(最多6张)" prop="bills">
            <el-upload
              action="/api/common/qiniu/upload"
              :limit="6"
              list-type="picture-card"
              class="imgWrap"
              ref="upload"
              multiple
              :headers="{ token: token }"
              :on-preview="handlePictureCardPreview"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :on-remove="handleRemove2"
              :file-list="fileList"
              :on-exceed="handleExceed"
              :http-request="uploadFile"
              accept="image/jpeg, image/png, image/gif"
              :auto-upload="true"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisibleUrl" append-to-body>
              <img width="100%" :src="dialogImageUrl" alt />
            </el-dialog>
          </el-form-item>
        </el-form>
        <template #footer>
          <el-row type="flex" justify="center">
            <el-button type="primary" :loading="loadingBill" @click="sumbitBill"
              >确定</el-button
            >
          </el-row>
        </template>
      </el-dialog>

      <el-dialog
        title="选择仓库"
        :visible.sync="dialogVisibleStash"
        width="400px"
        :before-close="handleClose"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        append-to-body
        center
      >
        <el-form
          class="staff-from"
          v-loading="loading"
          element-loading-text="加载中…"
          ref="elFormDom"
          label-width="100px"
          label-position="right"
          :model="form.data"
          :rules="form.rules"
          :size="subUnitSize"
        >
          <el-form-item label="选择仓库" prop="stash_id">
            <el-select v-model="form.data.stash_id">
              <el-option
                v-for="item in stashArr"
                :label="item.name"
                :value="item.id"
                :key="item.id"
              >
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px"
                  >{{ item.province_str }}{{ item.city_str }}</span
                >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <template #footer>
          <el-row type="flex" justify="center">
            <el-button
              type="primary"
              :loading="form.loading"
              :disabled="form.loading"
              @click="handeReceiveAllFun"
              >确定</el-button
            >
          </el-row>
        </template>
      </el-dialog>
    </div>
  </div>
</template>


<script>
import { getStorage } from "@/storage";
import { mixinTable } from "@/mixins/table.js";
export default {
  name: "purchaseReceive",
  mixins: [mixinTable],
  data() {
    return {
      userId: getStorage("userId"),
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      visible: false,
      info: {
        order_count: 0, //订单数量
        num: 0, //下单数量
        delivery_num: 0, //收货数量
        delivery_money: 0, //收货金额
        send_num: 0, //发货数量
        send_price: 0, //发货金额
        await_delivery_num: 0, //待收货数量
        city: "",
        stash_id: "",
      },
      currIndex: 0,
      currRow: {
        order: {},
      },
      tableData: [],
      sel_tableData: [],
      table: {
        total: 0,
      },

      loading: false,
      searchForm: {
        loading: false,
        data: {
          goods_name: "",
          supplier_name: "", //供应商、采购员
          address_id: "", //食堂
        },
      },
      multipleSelection: [],
      stashArr: [],
      dialogVisible: false,
      form: {
        loading: false,
        data: {
          stash_id: "", // 仓库id
        },
        rules: {
          stash_id: [{ required: true, message: "请选择", trigger: "change" }],
        },
      },
      formUnit: {
        loading: false,
        data: {
          cov_unit: "", // 采购单位
          cov_times: "", // 采购单位对应数量
        },
        rules: {
          cov_times: [{ required: true, message: "必填项", trigger: "change" }],
        },
      },
      formBill: {
        loading: false,
        data: {
          bind_id: "", // 供应商/采购员
          bills: "", //单据
        },
        rules: {
          type: [{ required: true, message: "请选择", trigger: "change" }],
          bind_id: [{ required: true, message: "请选择", trigger: "change" }],
          bills: [{ required: true, message: "请上传", trigger: "change" }],
        },
      },
      currGoodsRow: {}, // 当前操作行
      unitArr: [], // 计件单位
      dialogVisibleUnit: false, // 采购单位弹窗
      dialogVisibleBill: false, // 上传单据
      loadingBill: false,
      storeArr: [], // 食堂

      fileList: [],
      dialogImageUrl: "",
      dialogVisibleUrl: false,

      totalNumAll: 0, // 本次收货数量总计
      totalMoneyAll: 0, // 本次收货金额总计
      imgFileList: [],
      selLoading: false,
      goodArr: [],
      specArr: [],
      delivery_date: "",
      supplier_id: "",
      supplier_name: "",
      bills_purchase_ids: [],
      patchReceiveArr: [], // 批量收货选中的商品数据
      allDetailsArr: [], // 存所有采购单下的所有商品数据
      tableIndex: 0,
      is_address_sel: false, // 检测是否有支持待分拣的采购单
      checkAll: false, // 全选
      multipleSelection: [],
      dialogVisibleStash: false,
      receiveType: '', // 1部分收货，2全部收货
    };
  },
  async created() {
    this.currRow = { id: this.$route.query.purchase_id }; // 采购单id
    this.delivery_date = this.$route.query.delivery_date;
    this.supplier_id = this.$route.query.supplier_id;
    this.supplier_name = this.$route.query.supplier_name;
    await this.getStash();
    await this.getTable();
  },
  methods: {
    getTable() {
      let _params = {
        delivery_date: this.delivery_date, // 交货日期
        supplier_id: this.supplier_id, // 供应商/采购员id
        goods_name: this.searchForm.data.goods_name,
        address_id: this.searchForm.data.address_id,
        order_no: this.searchForm.data.order_no,
      };
      this.$http
        .get("/admin/purchase_order/infoByBind", { params: _params })
        .then((res) => {
          if (res.code == 1) {
            this.info.order_count = res.data.order_count; //订单数量
            this.info.num = res.data.num; //下单数量
            this.info.delivery_num = res.data.delivery_num; //收货数量
            this.info.delivery_money = res.data.delivery_money; //收货金额
            this.info.send_num = res.data.send_num; //发货数量
            this.info.send_price = res.data.send_price; //发货金额
            this.info.await_delivery_num = res.data.await_delivery_num; //待收货数量
            this.info.stash_id = !!res.data.stash_id ? res.data.stash_id : 0; //仓库id
            this.info.city = res.data.city;
            if (this.info.stash_id > 0) {
              this.form.data.stash_id = this.info.stash_id;
            } else {
              let curr_stash_id = this.stashArr.filter(
                (v) => v.city == this.info.city
              ); // 过滤出供应商地区的仓库
              if (curr_stash_id.length == 1) {
                this.form.data.stash_id = curr_stash_id[0].id;
                this.info.stash_id = curr_stash_id[0].id;
              } else {
                this.info.stash_id = 0;
              }
            }

            this.is_address_sel = res.data.list.some(
              (item) => item.is_sorter == 1
            ); // 检测是否有支持待分拣的采购单，如果有则返回true;
            if (res.data.list.length == 0) {
              this.$router.push("/purchase/list");
            }
            this.tableData = res.data.list.map((item) => {
              return {
                ...item,
                is_bills: !!item.bills ? true : false, // 该采购单是否上传单据
                details: item.details.map((v) => {
                  let _delivery_num_curr =
                    Number(v.num - v.delivery_num) > 0
                      ? Number(v.num * 100000 - v.delivery_num * 100000) /
                        100000
                      : 0; // 本次收货数量 (如果减出负数就默认为0) = 下单数量 - 已收货数量
                  if (v.is_send > 0 && v.status == 1)
                    _delivery_num_curr = v.sorter_num; // 本次收货数量，is_send > 0采购状态是待收货就取分拣数量
                  return {
                    len: item.details.length,
                    is_bills: !!item.bills ? true : false, //是否上传单据
                    delivery_num_curr: _delivery_num_curr, //本次收货数量
                    extent_curr:
                      Math.round(
                        v.last_purchase_price * 100 - v.old_purchase_price * 100
                      ) / 100, // 计算采购价上升或下降
                    delivery_money_curr: v.last_purchase_price, // 本次收货价格
                    total_money_curr:
                      (_delivery_num_curr * v.last_purchase_price).toFixed(2) *
                      1, // 本次收货金额（小计）= 本次收货数量 * 本次收货价格
                    ...v,
                    bind_id: v.bind_id == 0 ? "" : v.bind_id, // 供应商/采购员
                    cov_unit: !!v.cov_unit ? v.cov_unit : v.unit_name, // 采购单位

                    name: v.spec_name, // 规格名称
                    image: v.goods_image, // 商品图片
                    good_name: v.goods_name, // 商品名称
                    address_id: v.address_id == 0 ? "" : v.address_id, // 食堂id
                  };
                }),
              };
            });
            // 准备一个空数组
            this.sel_tableData = res.data.list.map((i, index) => {
              return [];
            });
            //取二级数组details，组成一个新数组
            this.allDetailsArr = this.tableData.reduce(
              (acc, item) => acc.concat(item.details),
              []
            );
            // 计算本次收货数量总计和本次收货金额总计
            this.totalNumAll = this.countNum(this.allDetailsArr);
            this.totalMoneyAll = this.countMoney(this.allDetailsArr);
            // 获取商品项数
            this.table.total = this.allDetailsArr.length;
            this.$nextTick(() => {
              // 替换文本把食堂换成用户
              this.replaceTextInElement(this.$el, "食堂", "用户");
            });
          }
        });
    },
    // 获取食堂
    getStore() {
      this.$http
        .get("/admin/path/storeList", { params: { page: 1, count: 10000 } })
        .then((res) => {
          if (res.code === 1) {
            this.storeArr = res.data.list;
          }
        });
    },
    // 获取启用中的仓库
    getStash() {
      // status 状态 1:启用 2:禁用
      this.$http
        .get("/admin/stash/list", {
          params: { page: 1, count: 10000, status: 1 },
        })
        .then((res) => {
          if (res.code == 1) {
            this.stashArr = res.data.list;
          }
        });
    },
    reset(done) {
      done && done();
    },
    toggle(show) {
      this.visible = show;
      !show && this.reset();
    },
    // 单条部分收货
    async handeReceivePart(row, tableItem) {
      if (!row.delivery_money_curr || row.delivery_money_curr == 0) {
        this.$message.warning("请先输入本次收货金额！");
        return;
      }
      // 部分收货状态且已部分收货的商品，收货数量允许填负数且（本次收货数量 + 已收货数量不能小于0）
      if (
        row.status == 4 &&
        Number(row.delivery_num_curr) + Number(row.delivery_num) < 0
      ) {
        this.$message.warning("单个商品累计收货数量不能小于0");
        return;
      }
      this.$confirm("请确认收货操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.currGoodsRow = row;
          this.receiveType = 1; // 1:单条部分收货，
          if (this.info.stash_id == 0) {
            //如果没有仓库要先选仓库
            this.dialogVisibleStash = true;
          } else {
            let data = {
              type: 1, //类型 1:单条部分收货 2:单条全部收货
              stash_id: this.form.data.stash_id, // 仓库id
              spec: [
                {
                  id: row.id, //明细ID
                  delivery_num: row.delivery_num_curr, //本次收货数量
                  delivery_money: row.delivery_money_curr, //本次收货价格
                  total_money: row.total_money_curr, // 本次收货小计
                },
              ],
            };
            console.log(data);
            this.receiveFun(data);
          }
        })
        .catch(() => {});
    },
    // 单条全部收货
    async handeReceiveAll(row, tableItem) {
      //则最后一条数据收货需要上传单据 (未上传单据不可以全部收货)
      if (tableItem.details.length == 1 && tableItem.is_bills == false) {
        this.$message.warning("未上传采购单据，请先上传单据");
        return;
      }
      if (!row.delivery_money_curr || row.delivery_money_curr == 0) {
        this.$message.warning("请先输入本次收货金额！");
        return;
      }
      this.$confirm("请确认收货操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.currGoodsRow = row;
          this.receiveType = 2; // 2单条全部收货
          if(this.info.stash_id == 0){ // 如果没有仓库先选仓库
            this.dialogVisible = true;
          } else {
            let data = {
              type: 2, //类型 1:单条部分收货 2:单条全部收货
              stash_id: this.form.data.stash_id, // 仓库id
              spec: [
                {
                  id: row.id, //明细ID
                  delivery_num: row.delivery_num_curr, //本次收货数量
                  delivery_money: row.delivery_money_curr, //本次收货价格
                  total_money: row.total_money_curr, // 本次收货小计
                },
              ],
            };
            console.log(data);
            this.receiveFun(data); // 收货方法
          }
        })
        .catch(() => {});
    },
    // 收货方法
    receiveFun(_params) {
      this.$http
        .post("/admin/purchase_order/patchReceive", _params)
        .then((res) => {
          if (res.code == 1) {
            this.dialogVisibleStash = false;
            this.form.data.stash_id = '';
            this.$message.success("操作成功！");
            setTimeout(() => {
              this.getTable(); // 延迟1秒请求接口，避免返回的采购单状态未更新
            }, 1000);
          }
        });
    },
    // 计算价格
    countPrice(row, index, tableIndex) {
      // 部分收货状态且已部分收货的商品，收货数量允许填负数
      if (row.status == 4) {
        const reg2 = /^-?\d+\.?\d*$/;
        if (row.delivery_num_curr && !reg2.test(row.delivery_num_curr)) {
          row.delivery_num_curr = row.delivery_num_curr
            .toString()
            .replace(/[^0-9-]/g, "");
        }
      } else {
        const reg = /^[\d]+$/;
        if (row.delivery_num_curr && !reg.test(row.delivery_num_curr)) {
          row.delivery_num_curr = row.delivery_num_curr
            .toString()
            .replace(/[^0-9.]/g, "");
        }
        if (row.delivery_money_curr && !reg.test(row.delivery_money_curr)) {
          row.delivery_money_curr = row.delivery_money_curr.replace(
            /[^0-9.]/g,
            ""
          );
        }
      }
      // 计算本次收货价格
      if (!!row.delivery_money_curr && !!row.delivery_num_curr) {
        row.total_money_curr =
          Math.round(
            parseFloat(row.delivery_num_curr * row.delivery_money_curr) * 100
          ) / 100;
        this.$set(this.tableData[tableIndex].details, index, row);
      }
      // 计算本次收货数量总计和本次收货金额总计
      let all = this.tableData.reduce(
        (acc, item) => acc.concat(item.details),
        []
      );
      this.totalNumAll = this.countNum(all);
      this.totalMoneyAll = this.countMoney(all);
      this.actionFun(row);
    },
    // 计算本次收货金额
    totalPrice(row, index) {
      const reg = /^[\d]+$/;
      if (row.total_money_curr && !reg.test(row.total_money_curr)) {
        row.total_money_curr = row.total_money_curr.replace(/[^0-9.]/g, "");
      }
      // 计算本次收货数量总计和本次收货金额总计
      let all = this.tableData.reduce(
        (acc, item) => acc.concat(item.details),
        []
      );
      this.totalNumAll = this.countNum(all);
      this.totalMoneyAll = this.countMoney(all);
      this.actionFun(row);
    },
    // 批量选择数据
    handleSelectionChange(val, tableItem, tableIndex) {
      this.multipleSelection = val;
      this.sel_tableData[tableIndex] = val;
      console.log(this.sel_tableData, "sel_tableData");
      if (val.length > 0) {
        this.bills_purchase_ids.push(tableItem.id);
      } else {
        this.bills_purchase_ids.map((id, i) => {
          if (id == tableItem.id) {
            this.bills_purchase_ids.splice(i, 1);
          }
        });
      }
    },
    countNum(arr) {
      console.log(arr, "012");
      let sum = 0;
      arr.map(function (item) {
        if (!!Number(item.delivery_num_curr)) {
          sum += Number(item.delivery_num_curr);
        }
      });
      return sum.toFixed(2) * 1;
    },
    countMoney(arr) {
      let sum = 0;
      arr.map(function (item) {
        if (!!Number(item.total_money_curr)) {
          sum += parseFloat(Number(item.total_money_curr) * 1000) / 1000;
        }
      });
      return sum.toFixed(2) * 1;
    },
    // 批量部分收货
    async handleBatch() {
      let arr = this.sel_tableData.filter((v) => v.length > 0);
      // 取二级数组，组成一个新数组
      this.patchReceiveArr = arr.reduce((acc, item) => acc.concat(item), []);
      if (this.patchReceiveArr.length > 0) {
        let num = this.countNum(this.patchReceiveArr); // 计算收货总数量
        let money = this.countMoney(this.patchReceiveArr); //计算收货总金额
        let is_money = this.patchReceiveArr.some(
          (item) => !item.delivery_money_curr || item.delivery_money_curr == 0
        ); // 检测提交的数据是否填了数量或金额
        let is_num = this.patchReceiveArr.some(
          (item) => !item.delivery_num_curr
        ); // 检测提交的数据是否填了数量或金额
        if (is_money == true) {
          this.$message.warning("请输入商品规格收货金额");
          return;
        }
        if (is_num == true) {
          this.$message.warning("请输入商品规格收货数量");
          return;
        }
        // 部分收货状态且已部分收货的商品，收货数量允许填负数且（本次收货数量 + 已收货数量不能小于0）
        let str = this.patchReceiveArr.some(
          (v) =>
            v.status == 4 &&
            Number(v.delivery_num_curr) + Number(v.delivery_num) < 0
        );
        if (str) {
          this.$message.warning("单个商品累计收货数量不能小于0");
          return;
        }
        this.$confirm(
          `已收货${this.patchReceiveArr.length}个商品，数量${num}，金额${money}元`,
          "确认部分收货吗",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            center: true,
          }
        )
          .then(() => {
            this.receiveType = 1;
            if(this.info.stash_id == 0) { // 若没有仓库先选仓库
              this.dialogVisibleStash = true;
            } else {
              let _spec = this.patchReceiveArr.map((v) => {
                return {
                  id: v.id, //明细ID
                  delivery_num: v.delivery_num_curr, //本次收货数量
                  delivery_money: v.delivery_money_curr, //本次收货价格
                  total_money: v.total_money_curr, // 本次收货小计
                };
              });
              let data = {
                type: 1, //类型 1:批量部分收货 2:批量全部收货
                stash_id: this.form.data.stash_id, // 仓库id
                spec: _spec,
              };
              console.log(data);
              // return;
              this.receiveFun(data); // 收货方法
            }
          })
          .catch(() => {});
      } else {
        this.$message.warning("请至少选择一条数据");
      }
    },
    // 批量全部收货
    async handleBatchAll() {
      let arr = this.sel_tableData.filter((v) => v.length > 0);
      // 取二级数组，组成一个新数组
      this.patchReceiveArr = arr.reduce((acc, item) => acc.concat(item), []);
      if (this.patchReceiveArr.length > 0) {
        //则最后一条数据收货需要上传单据 (未上传单据不可以全部收货)
        if (
          this.sel_tableData.some((item) => {
            return item.some((i) => {
              return i.len == item.length && !i.is_bills;
            });
          })
        ) {
          console.log(false);
          this.$message.warning("未上传采购单据，请先上传单据");
          return;
        }

        let num = this.countNum(this.patchReceiveArr); // 计算收货总数量
        let money = this.countMoney(this.patchReceiveArr); // 计算收货总金额
        let is_money = this.patchReceiveArr.some(
          (item) => !item.delivery_money_curr || item.delivery_money_curr == 0
        ); // 检测提交的数据是否填了数量或金额
        if (is_money == true) {
          this.$message.warning("请输入商品规格收货金额");
          return;
        }
        this.$confirm(
          `已收货${this.patchReceiveArr.length}个商品，数量${num}，金额${money}元`,
          "确定商品已经全部收货完成了吗",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            center: true,
          }
        )
          .then(() => {
            this.receiveType = 2;
            if(this.info.stash_id == 0) { // 若没有仓库先选仓库
              this.dialogVisibleStash = true;
            } else {
              let _spec = this.patchReceiveArr.map((v) => {
                return {
                  id: v.id, //明细ID
                  delivery_num: v.delivery_num_curr, //本次收货数量
                  delivery_money: v.delivery_money_curr, //本次收货价格
                  total_money: v.total_money_curr, // 本次收货小计
                };
              });
              let data = {
                type: 2, //类型 1:批量部分收货 2:批量全部收货
                stash_id: this.form.data.stash_id, // 仓库id
                spec: _spec,
              };
              console.log(data);
              // return;
              this.receiveFun(data); // 收货方法
            }
          })
          .catch(() => {});
      } else {
        this.$message.warning("请至少选择一条数据");
      }
    },
    // 返回
    handleReturn() {
      this.searchForm.data.address_id = "";
      this.searchForm.data.goods_name = "";
      this.searchForm.data.order_no = "";
      this.$emit("refresh");
      this.toggle(false);
      this.tableData = [];
    },
    handleClose() {
      this.dialogVisible = false;
      this.dialogVisibleUrl = false;
      this.dialogVisibleUnit = false;
      this.dialogVisibleBill = false;
      this.dialogVisibleStash = false;
      this.imgFileList = [];
      this.fileList = [];
    },
    // 获取计件单位
    getUnit() {
      this.$http
        .get("/admin/unit/list", { params: { page: 1, count: 1000 } })
        .then((res) => {
          if (res.code === 1) {
            this.unitArr = res.data.list;
          }
        });
    },
    // 修改采购单位
    openPopover(row, index, tableIndex) {
      this.dialogVisibleUnit = true;
      this.currGoodsRow = row; // 当前操作行
      this.currIndex = index;
      this.tableIndex = tableIndex;
      this.formUnit.data.cov_unit = row.cov_unit;
    },
    // 修改采购单位
    handleUnit() {
      this.$refs.elFormDomUnit.validate((valid) => {
        if (valid) {
          let _params = {
            detail_id: this.currGoodsRow.id, // 明细id
            cov_unit: this.formUnit.data.cov_unit,
            cov_times: this.formUnit.data.cov_times,
          };
          this.$http
            .post("/admin/purchase_order/updateUnit", _params)
            .then((res) => {
              if (res.code === 1) {
                this.$message.success("修改成功！");
                // 修改采购单位后不自动刷新接口，
                this.tableData[this.tableIndex].details[
                  this.currIndex
                ].cov_unit = this.formUnit.data.cov_unit;
                this.tableData[this.tableIndex].details[
                  this.currIndex
                ].cov_times = this.formUnit.data.cov_times;
                this.$set(
                  this.tableData[this.tableIndex].details[this.currIndex],
                  this.currIndex,
                  this.currRow
                );
                // this.getTable();
                this.formUnit.data.cov_unit = "";
                this.formUnit.data.cov_times = "";
                this.actionFun(this.currGoodsRow);
              }
            })
            .finally(() => {
              this.dialogVisibleUnit = false;
            });
        }
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisibleUrl = true;
    },
    // 图片上传成功
    handleAvatarSuccess(res, file) {
      this.formBill.data.bills = res.data.all_path_url;
      let strImg = {
        url: res.data.all_path_url,
        name: "",
      };
      this.fileList.push(strImg);
      this.$refs.elFormDomBill.clearValidate();
    },
    handleRemove2(file, fileList) {
      if (this.fileList.length > 0) {
        this.fileList.forEach((v, i) => {
          if (file.uid === v.uid) {
            this.fileList.splice(i, 1);
          }
        });
      }
    },
    // 图片上传之前
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning("图片尺寸超出5M，请修改");
      }
      return isLt5M;
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制最多只能选择 6 个文件`);
    },
    handleBill() {
      if (this.bills_purchase_ids.length > 0) {
        this.dialogVisibleBill = true;
        this.formBill.data.bind_id = "";
        this.fileList = [];
      } else {
        this.$message.warning("请选择需要上传单据的采购单");
      }
    },
    uploadFile(file) {
      this.imgFileList.push(file.file);
    },
    // 上传单据
    async sumbitBill() {
      // this.$refs.upload.submit();
      if (this.imgFileList.length < 1) {
        return this.$message.warning("请上传单据");
      }
      var formData = new FormData();
      this.imgFileList.forEach((v) => {
        formData.append("file[]", v);
      });
      this.loading = true;
      this.loadingBill = true;
      await this.$http
        .post("/common/qiniu/upload_multiple", { formData, type: "upload" })
        .then((res) => {
          if (res.code == 1) {
            this.fileList = res.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
      let _params = {
        ids: this.bills_purchase_ids.join("|"), //采购单ID  多个竖线隔开
        delivery_date: this.delivery_date,
        supplier_id: this.supplier_id, // 供应商/采购员
        bills: this.fileList.map((v) => v.all_path_url).join("|"),
      };
      this.$http
        .post("/admin/purchase_order/patchBill", _params)
        .then((res) => {
          if (res.code == 1) {
            this.dialogVisibleBill = false;
            this.$message.success("上传成功！");
            this.imgFileList = [];
            this.fileList = [];
            // this.getTable(); // 不自动刷新列表接口
            // 更新采购单是否上传单据状态
            this.tableData = this.tableData.map((item) => {
              if (this.bills_purchase_ids.indexOf(item.id) > -1) {
                return {
                  ...item,
                  is_bills: true,
                  details: item.details.map((i) => {
                    return {
                      ...i,
                      is_bills: true,
                    };
                  }),
                };
              } else {
                return {
                  ...item,
                };
              }
            });
          }
        })
        .finally(() => {
          this.loadingBill = false;
        });
    },
    numREG_EXP(row, index, tableItem, tableIndex) {
      const reg = /^[\d]+$/;
      // 任何单位的数量都可以输入5位小数
      if (row.num && !reg.test(row.num)) {
        row.num = row.num.match(/\d+(\.\d{0,5})?/)
          ? row.num.match(/\d+(\.\d{0,5})?/)[0]
          : "";
      }

      row.delivery_num_curr = !!row.delivery_num_curr
        ? row.delivery_num_curr
        : row.num; // 收货数量
      (row.total_money_curr =
        (row.delivery_num_curr * row.last_purchase_price).toFixed(2) * 1), // 本次收货金额（小计）= 本次收货数量 * 本次收货价格
        this.$set(this.tableData[tableIndex].details, index, row);
      this.totalNumAll = this.countNum(this.allDetailsArr);
      this.totalMoneyAll = this.countMoney(this.allDetailsArr);

      if (row.new == 1) {
        if (tableItem.is_sorter == 1 && row.address_id > 0) {
          this.handleEditInfo(tableIndex, tableItem, row, 1); // 保存编辑采购单接口(type:1新增，2编辑，3删除)
        } else if (tableItem.is_sorter == 1 && row.address_id == 0) {
          this.$message.warning("请选择食堂");
        } else {
          this.handleEditInfo(tableIndex, tableItem, row, 1);
        }
      } else if (row.new !== 1 && row.spec_id > 0) {
        this.handleEditInfo(tableIndex, tableItem, row, 2); // 保存编辑采购单接口(type:1新增，2编辑，3删除)
      }
      this.actionFun(row);
    },
    // 减
    onMinus(row, index, tableItem, tableIndex) {
      console.log(row, index);
      if (this.tableData[tableIndex].details.length > 1) {
        this.tableData[tableIndex].details.forEach((v, i) => {
          if (index == i) {
            this.tableData[tableIndex].details.splice(i, 1);
          }
        });
        console.log(this.specArr, "this.specArr,");
        this.specArr = this.specArr.filter((j) => j != row.id);
      } else {
        this.$message.warning("最后一条数据了!");
      }
      this.table.total -= 1; // 商品项数 减1
      this.totalNumAll = this.countNum(this.tableData); //计划采购总数量
      this.totalMoneyAll = this.countMoney(this.tableData);

      // 删除时向原数据中减一条数据
      this.allDetailsArr.forEach((v, i) => {
        if (v.spec_id == row.spec_id) {
          this.allDetailsArr.splice(i, 1);
        }
      });
      if (row.id > 0 && row.spec_id > 0) {
        this.handleEditInfo(tableIndex, tableItem, row, 3); // 保存编辑采购单接口(type:1新增，2编辑，3删除)
      }
    },
    // 加
    onAdd(row, index, tableIndex) {
      let str = {
        image: require("@/assets/img/default.jpg"),
        good_name: "",
        num: "",
        address_id: "",
        address_name: "",
        remarks: [{ context: "" }],
        id: 0,
        extent_curr: 0,
        new: 0, // 代表新添加的商品
      };
      this.tableData[tableIndex].details.push(str);
      this.goodArr = []; //清除上次的查询商品数据
      this.table.total += 1; // 商品项数 加1
    },
    remoteMethod(query, tableItem) {
      if (query !== "") {
        this.selLoading = true;
        let _params = {
          province: tableItem.province,
          city: tableItem.city,
          page: 1,
          count: 1000,
          status: 1,
          good_name: query,
        };
        this.$http
          .get("/admin/goods/specList", { params: _params })
          .then((res) => {
            if (res.code === 1) {
              this.goodArr = res.data.list;
            }
          })
          .finally(() => {
            this.selLoading = false;
          });
      } else {
        this.goodArr = [];
      }
    },
    // 选择商品
    changeGoods(value, index, tableIndex, row) {
      // is_sorter:1:支持待分拣，2：不支持待分拣
      var _address_name = "";
      if (this.tableData[tableIndex].is_sorter == 2) {
        // 不支持待分拣
        let newSpecid = [];
        newSpecid = this.tableData[tableIndex].details.map((v) => {
          return v.spec_id;
        });
        this.specArr = newSpecid;
        if (this.specArr.indexOf(value.id) >= 0) {
          this.$message.warning("已经添加过该规格");
          this.tableData[tableIndex].details[index].good_name = {}; // 当前行搜索值清空
          this.goodArr = []; // 当前行筛选数据清空
          return;
        }
      } else if (this.tableData[tableIndex].is_sorter == 1) {
        // 支持待分拣
        _address_name =
          row.address_id > 0
            ? this.storeArr.filter((v) => v.id == row.address_id)[0].name
            : ""; // 食堂名称
        let arr = this.tableData[tableIndex].details.filter((v) => {
          return v.spec_id == value.id && v.address_id == row.address_id;
        });
        if (arr.length > 0) {
          this.$message.warning("该食堂已经添加过该规格");
          this.tableData[tableIndex].details[index].good_name = {}; // 当前行搜索值清空
          this.goodArr = []; // 当前行筛选数据清空
          return;
        }
      }
      this.specArr.push(value.id);
      let _currRow = {
        ...value,
        detail_id: 0,
        address_id: row.address_id, //食堂id
        address_name: _address_name, // 食堂名称
        spec_id: value.id, // 规格id
        last_purchase_price: value.purchase_price, // 采购价
        num: "", // 下单数量
        delivery_num_curr: 0, // 本次收货数量
        delivery_money_curr: value.purchase_price, // 本次收货单价
        extent_curr: 0,
        remarks: [{ context: "" }], // 备注
        cov_unit: value.unit_name, // 单位
        cov_times: 0,
        new: 1, //新添加的商品已选择了商品
      };
      this.tableData[tableIndex].details[index] = _currRow;
      this.$set(this.tableData[tableIndex].details, index, { ..._currRow });
      this.allDetailsArr.push(_currRow);
    },
    // 选择食堂
    changeAddress(row, index, tableIndex, tableItem) {
      let arr = this.tableData[tableIndex].details.filter((v) => {
        return v.spec_id == row.spec_id && v.address_id == row.address_id;
      });
      if (arr.length > 1) {
        this.$message.warning("该食堂已经添加过该规格");
        this.tableData[tableIndex].details[index].address_id = ""; // 清空
        this.tableData[tableIndex].details[index].address_name = ""; //值清空
        return;
      } else {
        let _currRow = {
          ...row,
          address_name:
            row.address_id > 0
              ? this.storeArr.filter((v) => v.id == row.address_id)[0].name
              : "", // 食堂名称
        };
        this.tableData[tableIndex].details[index] = _currRow;
        this.$set(this.tableData[tableIndex].details, index, { ..._currRow });
      }
    },
    // 编辑备注
    changeRemark(row, tableIndex, tableItem) {
      if (row.spec_id > 0 && row.num > 0) {
        this.handleEditInfo(tableIndex, tableItem, row, 2); // 保存编辑采购单接口(type:1新增，2编辑，3删除)
      }
    },
    // 操作列表时同步原数据
    actionFun(row) {
      this.allDetailsArr.map((item, index) => {
        if (
          item.spec_id == row.spec_id &&
          (item.address_id == row.address_id || item.address_id == 0)
        ) {
          this.allDetailsArr[index] = row;
        }
      });
    },
    // 编辑采购单商品 1:新增 2:编辑 3:删除 保存方法
    handleEditInfo(tableIndex, tableItem, row, type) {
      let _params = {
        id: tableItem.id, //采购单ID
        type: type, //类型 1:新增 2:编辑 3:删除
        info: {
          id: row.id, //明细ID
          spec_id: row.spec_id, //规格ID
          num: row.num, //数量
          address_id: !!row.address_id ? row.address_id : "", //门店ID
          remark:
            row.remarks.length > 0
              ? row.remarks
                  .filter((item) => !!item.context)
                  .flatMap((j) => Object.values(j))
              : "", // 获取备注值不为空时，数组value集
        },
      };
      this.$http
        .post("/admin/purchase_order/operationDetail", _params)
        .then((res) => {
          if (res.code == 1) {
            let _params2 = {
              delivery_date: this.delivery_date,
              supplier_id: this.supplier_id,
              goods_name: this.searchForm.data.goods_name,
              address_id: this.searchForm.data.address_id,
              order_no: this.searchForm.data.order_no,
            };
            this.$http
              .get("/admin/purchase_order/infoByBind", { params: _params2 })
              .then((res) => {
                if (res.code == 1) {
                  res.data.list[tableIndex].details.map((item) => {
                    tableItem.details.forEach((item2) => {
                      if (
                        item2.new == 1 &&
                        item.spec_id == item2.spec_id &&
                        item.address_id == item2.address_id
                      ) {
                        item2.id = item.id;
                        item2.new = 0;
                      }
                    });
                  });
                  // tableItem.details[0].id = 0;
                  console.log(tableItem, "tableItem");
                }
              });
          }
        });
    },
    headerFirst(row) {
      // if(row.rowIndex == 1) {
      //   return  'display:none'
      // }
      return "background:#f5f5f5";
    },
    // 全选
    selectAll() {
      this.$nextTick(() => {
        this.tableData.forEach((table, index) => {
          this.$refs[`table${index}`][0].clearSelection();
          this.$refs[`table${index}`][0].toggleAllSelection();
        });
      });
    },
    // 取消全选
    selectClear() {
      this.tableData.forEach((table, index) => {
        this.$refs[`table${index}`][0].clearSelection();
      });
    },
    handeReceiveAllFun() {
      this.$refs.elFormDom.validate((valid) => {
        if (valid) {
          var data = {};
          if (this.patchReceiveArr.length > 0) {
            // 批量部分收货
            let _spec = this.patchReceiveArr.map((v) => {
              return {
                id: v.id, //明细ID
                delivery_num: !!v.delivery_num_curr ? v.delivery_num_curr : 0, //本次收货数量
                delivery_money: v.delivery_money_curr, //本次收货价格
                total_money: v.total_money_curr, // 本次收货小计
              };
            });
            data = {
              type: this.receiveType, //类型 1:部分收货 2:全部收货
              stash_id: this.form.data.stash_id, // 仓库id
              spec: _spec,
            };
          } else {
            data = {
              type: this.receiveType, //类型 1:部分收货 2:全部收货
              stash_id: this.form.data.stash_id || "",
              spec: [
                {
                  id: this.currGoodsRow.id, //明细ID
                  delivery_num: this.currGoodsRow.delivery_num_curr, //本次收货数量
                  delivery_money: this.currGoodsRow.delivery_money_curr, //本次收货价格
                  total_money: this.currGoodsRow.total_money_curr, // 本次收货小计
                },
              ],
            };
          }
          this.receiveFun(data); // 收货方法
        }
      });
    },
  },
};
</script>


<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
}
.addPerson__wrap .main {
  overflow-x: hidden;
  background: #fff;
}
.returnBtn {
  z-index: 99;
  box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%),
    0 2px 4px 0 rgb(232 237 250 / 50%);
}
.footerWrap {
  text-align: center;
  padding: 10px;
  box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%),
    0 2px 4px 0 rgb(232 237 250 / 50%);
  z-index: 99;
}
.itemTime {
  display: block;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
}
.itemTime::before {
  content: "";
  width: 5px;
  height: 15px;
  background: #2cb167;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.flex {
  display: flex;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.width200px {
  width: 200px;
}
.after-table {
  padding: 10px;
  font-size: 13px;
  color: #303030;
  text-align: right;
}
.extent {
  font-size: 10px;
}
.name {
  font-size: 14px;
}
.time {
  margin-left: 30px;
  color: #999;
  font-size: 14px;
}
.imgWrap {
  max-height: 180px;
  overflow: hidden;
}
.imgWrap ::v-deep .el-upload--picture-card {
  width: 80px !important;
  height: 80px !important;
  line-height: 80px !important;
}
.imgWrap ::v-deep .el-upload-list__item {
  width: 80px !important;
  height: 80px !important;
}
 .selected-row {
   background-color: #f5f7fa !important;
 }

::v-deep .el-table tbody tr:hover > td {
  background-color: #fff !important;
}
.el-table tbody tr:hover > td {
  background-color: #fff !important;
}
.el-select-dropdown__item {
  display: flex;
  flex-direction: column;
  height: auto !important;
}
.mainBox::-webkit-scrollbar {
  width: 0px;
}

::v-deep .el-table th.el-table__cell {
  user-select: initial;
}
</style>
